import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'
import {
  ProcessClient,
  checkApiResponse,
  ProcessSubjectClient,
  ProcessPositionClient,
  ProcessOtherSourceClient,
  ProcessPublicSourceClient,
  ProcessPaperDocClient,
  ProcessSourceSubjectClient,
  ProcessSystemsClient,
  ProcessSystemsSubjectClient,
  ProcessThirdPartyClient,
  ProcessThirdPartySubjectsClient,
  SearchProcessesClient,
  DefaultProcessesClient,
  CopyProcessClient,
  InitProcessClient,
  ProcessToActiveClient,
  DeleteProcessSourceSubjectClient,
} from '@/api/clients'

export default {
  // Process actions
  async fetchProcesses(context, payload) {
    const processClient = new ProcessClient()
    const result = await processClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async orderingProcesses(context, payload) {
    const processClient = new ProcessClient()
    const result = await processClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async fetchAllProcessData(context, payload) {
    const initProcessClient = new InitProcessClient()
    const result = await initProcessClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_INIT_PROCESS_DATA', result.data)
    }
    await context.dispatch('company/fetchAllSystems', { company_id: payload.company_id }, { root: true })
    await context.dispatch('system/fetchSystems', { company_id: payload.company_id }, { root: true })
    await context.dispatch('company/fetchThirdParties', { company_id: payload.company_id }, { root: true })
  },

  async fetchProcess(context, payload) {
    // Получаем процесс
    const processClient = new ProcessClient()
    const result = await processClient.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESSES', result.data)
    }
  },

  async fetchDefaultProcesses(context, payload) {
    // Получаем процессы по умолчанию
    const defaultProcessesClient = new DefaultProcessesClient()
    const result = await defaultProcessesClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_DEFAULT_PROCESSES', result.data)
    }
  },

  async addProcess(context, payload) {
    // Добавляем процесс
    const processClient = new ProcessClient()
    const result = await processClient.create(payload)
    if (result.data?.id) {
      context.commit('ADD_PROCESS', result.data)
      if (['Analyst', 'Client'].includes(context.rootState.user.user?.status)) {
          context.state.editable_processes.push(result.data.id)
      }
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_create'), { root: true })
      await context.dispatch('company/fetchCompanyTags', { company_id: payload.company_id }, { root: true })
      return result
    }
    if (result.data?.freemium) {
      // Превышен лимит анкет для демо компании
      context.commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
    } else {
      // Если не удается создать процесс
      context.commit('DANGER_TOAST', i18n.global.t('company.processes.creating_error'), { root: true })
    }
  },

  async copyProcess(context, payload) {
    // Копирование процесса
    const copyProcessClient = new CopyProcessClient()
    const result = await copyProcessClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS', result.data)
      context.commit('ADD_EDITABLE_PROCESSES', result.data.id)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_create'), { root: true })
    }
    // Обновляем анкету Отделы и должности если копируем данные ИСПДн, используемые в рамках копируемого процесса
    if (payload.is_copy_systems) {
      await context.dispatch('company/fetchDepartment', payload.company, { root: true })
    }
    return result
  },

  async deleteProcess(context, payload) {
    // Удаляет процесс
    const processClient = new ProcessClient()
    const result = await processClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS', payload.id ? payload.id : payload.uuid)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.process_delete'), { root: true })
    }
  },

  updateProcess: _.debounce(async (context, payload) => {
    if (context.rootState.company?.public_key?.comments_only) {
      return
    }
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_PROCESS', { fields, getters: context.getters })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const processClient = new ProcessClient()
    const result = await processClient.update(
      {
        id: payload.form.id,
        company_id: payload.form.company,
        fields: { ...fields, id: payload.form.id, percent: context.getters.percent },
      },
      !!context.state.user,
    )
    await checkApiResponse(result, context)
    if (result.error) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchProcess', { company_id: payload.form.company, process_id: payload.form.id })
    }
  }, 500),

  async deleteProcessDocument(context, payload) {
    // Обновляем поле анкеты
    if (context.rootState.company?.public_key?.comments_only) {
      return
    }
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    const processClient = new ProcessClient()
    const result = await processClient.update({
      id: payload.form.id,
      company_id: payload.form.company,
      fields: { ...fields, percent: context.getters.percent },
    })
    await checkApiResponse(result, context)
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от процесса
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
      context.commit('UPDATE_PROCESS', { fields, getters: context.getters })
    }
  },

  async updateProcessName(context, payload) {
    context.commit('SET_PROCESS', payload.form)
    await context.dispatch('updateProcess', payload)
  },

  // Subject
  async fetchProcessSubjects(context, payload) {
    const processSubjectClient = new ProcessSubjectClient()
    const result = await processSubjectClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SUBJECTS', result.data)
    }
  },

  async addSubject(context, payload) {
    // Добавляем субъекта
    const processSubjectClient = new ProcessSubjectClient()
    const result = await processSubjectClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SUBJECT', result.data)
    }
    await context.dispatch('company/fetchSubjects', { company_id: payload.company_id }, { root: true })
  },

  async deleteSubject(context, payload) {
    // Удаляет субъект в процессе
    const processSubjectClient = new ProcessSubjectClient()
    const result = await processSubjectClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SUBJECT', payload.id)
    }
    await context.dispatch('company/fetchSubjects', {company_id: payload.company_id}, { root: true })
    await context.dispatch('fetchSourceSubjects', payload)
    await context.dispatch('fetchProcessSystemSubjects', payload)
    await context.dispatch('fetchProcessThirdPartySubjects', payload)
  },

  updateSubject: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient()
    const result = await processSubjectClient.update({
      ...payload,
      id: payload.form.id,
      fields: { ...fields, id: payload.form.id },
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', fields)
    }
  }, 500),

  async deleteSubjectDocument(context, payload) {
    let uuids = {}
    uuids[payload.field_name] = payload.new_uuids
    uuids['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient()
    const result = await processSubjectClient.update({
      ...payload,
      id: uuids['id'],
      fields: uuids,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', uuids)
    }
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от субъекта
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async updateSubjectFields(context, payload) {
    // Обновляем поля действий в анкете
    let fields = {}
    fields = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new ProcessSubjectClient()
    const result = await processSubjectClient.update({
      ...payload,
      id: fields['id'],
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SUBJECT', fields)
    }
  },

  // Position actions
  async addPosition(context, payload) {
    // Добавляем отдел
    const addPositionClient = new ProcessPositionClient()
    const result = await addPositionClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_POSITION', result.data)
    }
  },

  async deletePosition(context, payload) {
    // Удаляет процесс
    const processPositionClient = new ProcessPositionClient()
    const result = await processPositionClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_POSITION', payload.id)
    }
  },

  // Субъекты привязанные к источникам
  async fetchSourceSubjects(context, payload) {
    const processSourceSubjectClient = new ProcessSourceSubjectClient()
    const result = await processSourceSubjectClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SOURCE_SUBJECTS', result.data)
    }
  },

  async addSourceSubject(context, payload) {
    // Добавляем субъект источника
    const processSourceSubjectClient = new ProcessSourceSubjectClient()
    const result = await processSourceSubjectClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SOURCE_SUBJECT', result.data)
    }
  },

  async deleteSourceSubject(context, payload) {
    // Удаляет субъект источника
    const deleteProcessSourceSubjectClient = new DeleteProcessSourceSubjectClient()
    const result = await deleteProcessSourceSubjectClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SOURCE_SUBJECT', payload.id)
    }
  },

  updateSourceSubject: _.debounce(async (context, payload) => {
    // Обновляем поле анкеты
    let data = {}
    data[payload.field_name] = payload.value
    const processSourceSubjectClient = new ProcessSourceSubjectClient()
    const result = await processSourceSubjectClient.update({
      ...payload,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SOURCE_SUBJECT', result.data)
    }
    await context.dispatch('fetchProcessSubjects', { company_id: payload.company_id, process_id: payload.process_id })
  }, 100),

  // Публичные источники привязанные процессу
  async addPublicSource(context, payload) {
    // Добавляем субъект источника
    const processPublicSourceClient = new ProcessPublicSourceClient()
    const result = await processPublicSourceClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PUBLIC_SOURCE', result.data)
    }
  },

  async deletePublicSource(context, payload) {
    const deletePublicSourceClient = new ProcessPublicSourceClient()
    const result = await deletePublicSourceClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PUBLIC_SOURCE', payload.id)
    }
  },

  // Другие источники привязанные процессу
  async addOtherSource(context, payload) {
    const addOtherSourceClient = new ProcessOtherSourceClient()
    const result = await addOtherSourceClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_OTHER_SOURCE', result.data)
    }
  },

  async deleteOtherSource(context, payload) {
    const delOtherSource = new ProcessOtherSourceClient()
    const result = await delOtherSource.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_OTHER_SOURCE', payload.id)
    }
  },

  // Бумажные документы привязанные процессу
  async fetchPaperDocs(context, payload) {
    const fetchPaperDoc = new ProcessPaperDocClient()
    const result = await fetchPaperDoc.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PAPER_DOCS', result.data)
    }
  },

  async addPaperDocs(context, payload) {
    const addPaperDoc = new ProcessPaperDocClient()
    const result = await addPaperDoc.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PAPER_DOCS', result.data)
    }
    if (result) {
      return result
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.processes.error_name'), { root: true })
    }
  },

  async deletePaperDocs(context, payload) {
    const paperDocClient = new ProcessPaperDocClient()
    const result = await paperDocClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PAPER_DOC', payload.id)
    }
  },

  async updatePaperDoc(context, payload) {
    // // Обновляем документ
    let data = {}
    data[payload.field_name] = payload.value
    const processPaperDocument = new ProcessPaperDocClient()
    const result = await processPaperDocument.update({
      ...payload,
      id: payload.form.id,
      fields: data,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PAPER_DOC', result.data)
    }
  },

  // Системы привязанные процессу
  async fetchProcessSystems(context, payload) {
    const fetchProcessSys = new ProcessSystemsClient()
    const result = await fetchProcessSys.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SYSTEMS', result.data)
    }
  },

  async addProcessSystem(context, payload) {
    const addProcessSys = new ProcessSystemsClient()
    const result = await addProcessSys.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SYSTEM', result.data)
    }
  },

  async deleteProcessSystem(context, payload) {
    const deleteProcessSystems = new ProcessSystemsClient()
    const result = await deleteProcessSystems.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SYSTEM', payload.id)
    }
  },

  async updateProcessSystem(context, payload) {
    // Обновляем систему
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessSys = new ProcessSystemsClient()
    const result = await updateProcessSys.update({
      ...payload,
      id: payload.form.id,
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SYSTEM', result.data)
    }
    if (payload.field_name === 'positions') {
      await context.dispatch('company/fetchDepartment', { company_id: payload.company_id }, { root: true })
    }
  },

  // Субъекты системы привязанной к процессу
  async fetchProcessSystemSubjects(context, payload) {
    const processSystemsSubjectClient = new ProcessSystemsSubjectClient()
    const result = await processSystemsSubjectClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_SYSTEM_SUBJECTS', result.data)
    }
  },

  async addProcessSystemSubject(context, payload) {
    const addProcessSystemsSubj = new ProcessSystemsSubjectClient()
    const result = await addProcessSystemsSubj.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_SYSTEM_SUBJECT', result.data)
    }
  },

  async deleteProcessSystemSubject(context, payload) {
    const deleteProcessSystemsSubj = new ProcessSystemsSubjectClient()
    const result = await deleteProcessSystemsSubj.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_SYSTEM_SUBJECT', payload.id)
    }
  },

  updateProcessSystemSubject: _.debounce(async (context, payload) => {
    // Обновляем систему
    let fields = {
      process_system: payload.process_system,
      subject: payload.subject,
    }
    fields[payload.field_name] = payload.value
    const processSystemsSubjectClient = new ProcessSystemsSubjectClient()
    const result = await processSystemsSubjectClient.update({
      ...payload,
      id: payload.form.id,
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_SYSTEM_SUBJECT', result.data)
    }
    await context.dispatch('fetchProcessSubjects', { company_id: payload.company_id, process_id: payload.process_id })
  }, 100),

  async fetchProcessThirdParties(context, payload) {
    const fetchSProcessThirdPartyClient = new ProcessThirdPartyClient(payload.company, payload.process)
    const result = await fetchSProcessThirdPartyClient.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_THIRD_PARTIES', result.data)
    }
  },

  async addProcessThirdParty(context, payload) {
    const processThirdPartyClient = new ProcessThirdPartyClient()
    const result = await processThirdPartyClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_THIRD_PARTY', result.data)
    }
  },

  async deleteProcessThirdParty(context, payload) {
    const deleteProcessThParty = new ProcessThirdPartyClient()
    const result = await deleteProcessThParty.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_THIRD_PARTY', payload.id)
    }
  },

  updateProcessThirdParty: _.debounce(async (context, payload) => {
    // Обновляем третье лицо в процессе
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessThParty = new ProcessThirdPartyClient()
    const result = await updateProcessThParty.update({
      ...payload,
      id: payload.form.id,
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_THIRD_PARTY', result.data)
    }
  }, 1000),

  async deleteDocumentThirdParty(context, payload) {
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    const processThirdPartyClient = new ProcessThirdPartyClient()
    const result = await processThirdPartyClient.update({
      ...payload,
      fields,
    })
    if (await checkApiResponse(result, context)) {
      //Удаляем документ, если смогли отвязать
      context.commit('UPDATE_PROCESS_THIRD_PARTY', { ...fields, id: payload.id })
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async fetchProcessThirdPartySubjects(context, payload) {
    const processThirdPartySubjectsClient = new ProcessThirdPartySubjectsClient()
    const result = await processThirdPartySubjectsClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_PROCESS_THIRD_PARTY_SUBJECTS', result.data)
    }
  },

  async addProcessThirdPartySubject(context, payload) {
    const addProcessThirdPartySubj = new ProcessThirdPartySubjectsClient()
    const result = await addProcessThirdPartySubj.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_PROCESS_THIRD_PARTY_SUBJECT', result.data)
    }
  },

  async deleteProcessThirdPartySubject(context, payload) {
    const delProcessThirdPartySubj = new ProcessThirdPartySubjectsClient()
    const result = await delProcessThirdPartySubj.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_PROCESS_THIRD_PARTY_SUBJECT', payload.id)
    }
  },

  updateProcessThirdPartySubject: _.debounce(async (context, payload) => {
    // Обновляем субъект третьего лица
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessThirdPartySubj = new ProcessThirdPartySubjectsClient()
    const result = await updateProcessThirdPartySubj.update({
      ...payload,
      id: payload.form.id,
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_PROCESS_THIRD_PARTY_SUBJECT', result.data)
    }
    await context.dispatch('fetchProcessSubjects', {
      company_id: payload.company_id,
      process_id: payload.process_id,
    })
  }, 100),

  async searchProcesses(context, payload) {
    const searchProcess = new SearchProcessesClient()
    const result = await searchProcess.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('FILTER_PROCESSES', result.data)
    }
  },

  async filteredTagsProcesses(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/processes/?tags=${payload.tags}&limit=${payload.limit}`,
      context,
      {},
      'FILTER_PROCESSES',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },

  // Запрашиваем неактивные процессы
  async fetchInactiveProcesses(context, payload) {
    const processActiveClient = new ProcessToActiveClient()
    const result = await processActiveClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_INACTIVE_PROCESSES', result.data)
    }
  },

  //Переместить процесс в активные
  async moveProcessToActive(context, payload) {
    // const processClient = new ProcessToActiveClient()
    // const result = await processClient.create(payload)
    const result = await api.query(
      'CREATE',
      `/companies/${payload.company_id}/processes/inactive/${payload.id}/`,
      context,
      payload,
      '',
    )
    if (await checkApiResponse(result, context)) {
      context.commit('SUCCESS_TOAST', i18n.global.t('company.inactive_processes.move_active'), { root: true })
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.inactive_processes.move_error'), { root: true })
    }
    await context.dispatch('fetchProcesses', { company_id: payload.company_id, offset: 0, limit: 100 })
    await context.dispatch('fetchInactiveProcesses', { company_id: payload.company_id })
  },

  async moveProcessToBlocked(context, payload) {
    //Переместить процесс в неактивные
    const processClient = new ProcessClient()
    const result = await processClient.update(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SUCCESS_TOAST', i18n.global.t('company.inactive_processes.move_active'), { root: true })
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.inactive_processes.move_error'), { root: true })
    }
    await context.dispatch('fetchProcesses', { company_id: payload.company_id, offset: 0, limit: 100 })
    await context.dispatch('fetchInactiveProcesses', { company_id: payload.company_id })
  },
}
