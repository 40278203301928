export default {
  SET_COMPANIES(state, companies) {
    state.companies = companies
    state.companies_loaded = true
  },

  SET_COMPANY(state, company) {
    state.company = company
  },

  CHANGE_COMPANY_LOADED_STATUS(state, status) {
    state.company_loaded = status
  },

  CLEAR_LOADED(state) {
    state.companies_loaded = false
    state.company_loaded = false
  },

  ADD_COMPANY(state, company) {
    state.companies.push(company)
  },

  DELETE_COMPANY(state, index) {
    state.companies.splice(
      state.companies.findIndex(c => c.id === index),
      1,
    )
  },

  UPDATE_COMPANY(state, company) {
    state.company = company
    state.companies[state.companies.findIndex(c => c.id === company.id)] = company
  },

  SET_EDITED_APP(state, id) {
    state.edited_app = id
  },

  SET_INFORMATION(state, information) {
    state.information = information
  },

  UPDATE_INFORMATION(state, information) {
    state.information = { ...state.information, ...information }
  },

  SET_SITE_APP(state, site_app) {
    state.site_app = site_app
  },

  UPDATE_SITE_APP(state, site_app) {
    state.site_app = { ...state.site_app, ...site_app }
  },

  SET_SITES(state, sites) {
    state.sites = sites
  },

  ADD_SITE(state, site) {
    state.sites.push(site)
  },

  DELETE_SITE(state, id) {
    state.sites.splice(
      state.sites.findIndex(c => c.id === id),
      1,
    )
  },

  UPDATE_SITE(state, site) {
    state.sites[state.sites.findIndex(s => s.id === site.id)] = site
  },

  SET_SITE_FORMS(state, forms) {
    state.site_forms = forms
  },

  ADD_SITE_FORM(state, site_form) {
    state.site_forms.push(site_form)
  },

  DELETE_SITE_FORM(state, id) {
    state.site_forms.splice(
      state.site_forms.findIndex(s => s.id === id),
      1,
    )
  },

  UPDATE_SITE_FORM(state, form) {
    state.site_forms[state.site_forms.findIndex(s => s.id === form.id)] = form
  },

  SET_APPS(state, apps) {
    state.apps = apps
  },

  ADD_APP(state, app) {
    state.apps.push(app)
  },

  UPDATE_APP(state, app) {
    state.apps[state.apps.findIndex(s => s.id === app.id)] = app
  },

  DELETE_APP(state, id) {
    state.apps.splice(
      state.apps.findIndex(c => c.id === id),
      1,
    )
  },

  SET_APP_FORMS(state, forms) {
    state.app_forms = forms
  },

  ADD_APP_FORM(state, form_app) {
    state.app_forms.push(form_app)
  },

  DELETE_APP_FORM(state, id) {
    state.app_forms.splice(
      state.app_forms.findIndex(c => c.id === id),
      1,
    )
  },

  UPDATE_APP_FORM(state, app_form) {
    state.app_forms[state.app_forms.findIndex(s => s.id === app_form.id)] = app_form
  },

  SET_COMMISSION(state, commission) {
    state.commission = commission
  },

  UPDATE_COMMISSION(state, commission) {
    state.commission = { ...state.commission, ...commission }
  },

  SET_DEPARTMENT(state, department) {
    state.department = department
  },

  UPDATE_DEPARTMENT(state, department) {
    state.department = { ...state.department, ...department }
  },

  SET_SUBJECTS(state, subjects) {
    state.subjects = subjects
  },

  ADD_SUBJECT(state, subject) {
    state.subjects.push(subject)
  },

  UPDATE_SUBJECT(state, subject) {
    state.subjects[state.subjects.findIndex(s => s.id === subject.id)] = subject
  },

  DELETE_SUBJECT(state, index) {
    state.subjects.splice(
      state.subjects.findIndex(c => c.id === index),
      1,
    )
  },

  SET_COMPANY_TAGS(state, tags) {
    state.company_tags = tags
  },

  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },

  SET_COMPANY_PROCESS_SYSTEMS(state, data) {
    state.company_process_systems = data
  },

  SET_PROCESS_SUBJECT_OTHER_ACTIONS(state, data) {
    state.process_subject_other_actions = data
  },

  ADD_PROCESS_SUBJECT_OTHER_ACTIONS(state, actions) {
    const split_actions = actions.split(/\s*;\s*/)
    const new_action = split_actions.filter(action => !state.process_subject_other_actions.includes(action)).join()
    new_action.length ? state.process_subject_other_actions.push(new_action) : ''
  },

  SET_KEYS(state, keys) {
    state.keys = keys
  },

  SET_PUBLIC_KEY(state, key) {
    state.public_key = key
  },

  SET_KEY(state, key) {
    if (state.keys.findIndex(k => k.token === key.token) !== -1) {
      state.keys[state.keys.findIndex(k => k.token === key.token)] = key
    } else {
      state.keys.push(key)
    }
  },

  DELETE_KEY(state, id) {
    state.keys.splice(
      state.keys.findIndex(k => k.token === id),
      1,
    )
  },

  SET_OFFICES(state, offices) {
    state.offices = offices
  },

  ADD_OFFICE(state, office) {
    state.offices.push(office)
  },

  DELETE_OFFICE(state, id) {
    state.offices.splice(
      state.offices.findIndex(k => k.id === id),
      1,
    )
  },

  SET_PROCESS_SUBJECTS(state, payload) {
    state.process_subjects = payload
  },

  SET_DIRECTORY_ITEMS(state, payload) {
    state.directory_items = payload
    state.directory_items_loaded = true
  },

  ADD_DIRECTORY_ITEM(state, item) {
    state.directory_items.push(item)
  },

  UPDATE_DIRECTORY_ITEM(state, item) {
    state.directory_items[state.directory_items.findIndex(s => s.id === item.id)] = item
  },

  DELETE_DIRECTORY_ITEM(state, id) {
    state.directory_items.splice(
      state.directory_items.findIndex(d => d.id === id),
      1,
    )
  },

  SET_MACRO_GOALS(state, payload) {
    state.macro_goals = payload
  },

  ADD_MACRO_GOAL(state, item) {
    state.macro_goals.push(item)
  },

  DELETE_MACRO_GOAL(state, id) {
    state.macro_goals.splice(
      state.macro_goals.findIndex(m => m.id === id),
      1,
    )
  },

  SET_THIRD_PARTIES(state, parties) {
    state.third_parties = parties
  },

  ADD_THIRD_PARTY(state, party) {
    state.third_parties.push(party)
  },

  UPDATE_THIRD_PARTY(state, payload) {
    state.third_parties[state.third_parties.findIndex(p => p.id === payload.id)] = {
      ...state.third_parties[state.third_parties.findIndex(p => p.id === payload.id)],
      ...payload,
    }
  },

  DELETE_THIRD_PARTY(state, id) {
    state.third_parties.splice(
      state.third_parties.findIndex(tp => tp.id === id),
      1,
    )
  },

  ADD_THIRD_PARTY_SUBJECT(state, subject) {
    state.third_party_subjects.push(subject)
  },

  DELETE_THIRD_PARTY_SUBJECT(state, id) {
    state.third_party_subjects.splice(
      state.third_party_subjects.findIndex(tp => tp.id === id),
      1,
    )
  },

  UPDATE_THIRD_PARTY_SUBJECT(state, subject) {
    state.third_party_subjects[state.third_party_subjects.findIndex(s => s.id === subject.id)] = {
      ...state.third_party_subjects[state.third_party_subjects.findIndex(s => s.id === subject.id)],
      ...subject,
    }
  },

  UPDATE_PERCENT_DEPARTMENT(state, payload) {
    state.department.percent = payload.percent
  },

  UPDATE_PERCENT_COMMISSION(state, payload) {
    state.commission.percent = payload.percent
  },

  UPDATE_PERCENT_INFORMATION(state, payload) {
    state.information.percent = payload.percent
  },

  UPDATE_PERCENT_SITEAPP(state, payload) {
    state.site_app.percent = payload.percent
  },

  ADD_DOCUMENT(state, document) {
    if (state.documents.findIndex(doc => doc.uuid === document.uuid) === -1) {
      state.documents = [...state.documents, document]
    }
  },

  DEL_DOCUMENT(state, uuid) {
    state.documents.splice(
      state.documents.findIndex(el => el.uuid === uuid),
      1,
    )
  },

  SET_RISKS(state, risks) {
    state.risks = risks
  },

  SET_RISK(state, risk) {
    state.risks[state.risks.findIndex(r => r.id === risk.id)] = risk
  },

  SET_COMMENTS(state, comments) {
    state.comments = comments
  },

  ADD_COMMENT(state, comment) {
    state.comments.push(comment)
  },

  DELETE_COMMENT(state, id) {
    state.comments.splice(
      state.comments.find(c => c.id === id),
      1,
    )
  },

  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions
  },

  ADD_SUBSCRIPTION(state, subscription) {
    state.subscriptions.push(subscription)
  },

  UPDATE_SUBSCRIPTION(state, subscription) {
    state.subscriptions[state.subscriptions.findIndex(s => s.id === subscription.id)] = subscription
  },

  DEL_SUBSCRIPTION(state, id) {
    state.subscriptions.splice(
      state.subscriptions.findIndex(s => s.id === id),
      1,
    )
  },

  SET_MAILING_DATE(state, mailing_dates) {
    state.mailing_dates = mailing_dates
  },

  ADD_MAILING_DATE(state, mailing_date) {
    state.mailing_dates.push(mailing_date)
  },

  DEL_MAILING_DATE(state, id) {
    state.mailing_dates.splice(
      state.mailing_dates.findIndex(s => s.id === id),
      1,
    )
  },

  SET_DETAIL(state, detail) {
    state.detail_dashboard = detail
  },

  SET_EDITOR_EMAIL(state, email) {
    state.editor_email = email
  },

  SET_SECTIONS_COMMENTS(state, comments) {
    state.sections_comments = comments
  },

  ADD_SECTION_COMMENT(state, comment) {
    state.sections_comments.push(comment)
    if (comment.field_name === 'process') {
      this.state.process.processes[
        this.state.process.processes.findIndex(p => p.id === comment.object_id)
      ].has_comments = true
    } else {
      this.state.system.systems[this.state.system.systems.findIndex(p => p.id === comment.object_id)].has_comments =
        true
    }
  },

  UPDATE_SECTION_COMMENT(state, comment) {
    state.sections_comments[state.sections_comments.findIndex(c => c.id === comment.id)] = comment
  },

  DELETE_SECTION_COMMENT(state, id) {
    const index = state.sections_comments.findIndex(item => item.id === id)
    const [delete_comment] = state.sections_comments.splice(index, 1)
    if (state.sections_comments.length) {
      let target_array =
        delete_comment.field_name === 'process' ? this.state.process.processes : this.state.system.systems
      target_array[target_array.findIndex(ta => ta.id === delete_comment.object_id)].has_comments = false
    }
  },

  SET_ADDRESS_ITEMS(state, items) {
    state.address_items = items
  },

  ADD_ADDRESS_ITEM(state, item) {
    state.address_items.push(item)
  },

  UPDATE_ADDRESS_ITEM(state, item) {
    state.address_items[state.address_items.findIndex(el => el.id === item.id)] = item
  },

  DELETE_ADDRESS_ITEM(state, id) {
    state.address_items.splice(
      state.address_items.findIndex(el => el.id === id),
      1,
    )
  },

  SET_MAILING_CONTEXT(state, context) {
    state.mailing_context = context
  },

  SET_COMPANY_THIRD_PARTIES(state, data) {
    state.company_third_parties_details = data
  },

  SET_THREAT_FORM(state, data) {
    state.threat_form = data
  },

  SET_CRIMINALS(state, data) {
    state.criminal = data
  },

  UPDATE_CRIMINAL(state, data) {
    state.criminal['context'] = data
  },

  SET_TECHNOLOGIES(state, data) {
    state.init_technologies = data
  },

  SET_SECURITY_TECHNOLOGIES(state, data) {
    state.security_technologies = data
  },

  UPDATE_SECURITY_TECHNOLOGIES(state, data) {
    state.security_technologies['context'] = data
  },

  SET_THREAT_ASSESMENTS(state, data) {
    state.threat_assesments = data
  },

  SET_COMPANY_EMPLOYEES(state, data) {
    state.employees = data
  },

  ADD_COMPANY_EMPLOYEE(state, item) {
    state.employees.push(item)
  },

  UPDATE_COMPANY_EMPLOYEE(state, employee) {
    state.employees[state.employees.findIndex(s => s.id === employee.id)] = {
      ...state.employees[state.employees.findIndex(s => s.id === employee.id)],
      ...employee,
    }
  },

  DELETE_COMPANY_EMPLOYEE(state, id) {
    state.employees.splice(
      state.employees.findIndex(el => el.id === id),
      1,
    )
  },

  ADD_USER_COMPANY(state, user_id) {
    state.company.users.push(user_id)
  },

  DELETE_USER_COMPANY(state, payload) {
    state.company.users.splice(
      state.company.users.findIndex(el => el === payload.user.id),
      1,
    )
  },
}
